import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './pages/index';
import { rootReducer } from './models/index';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
const store = createStore(rootReducer, applyMiddleware(thunk));

const APPNAME = process.env.REACT_APP_BUILD_MODE;
Sentry.init({
  dsn: 'https://605019bd2f964d7db685ea5356882abc@o1089454.ingest.us.sentry.io/6111028',
  integrations: [new Integrations.BrowserTracing()],
  environment: APPNAME.toLocaleLowerCase(),
  initialScope: {
    tags: {
      team: 'front-end',
    },
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  maxValueLength: 250, // Batasi ukuran log yang dikirim
});
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
