import { CONNECTION } from '../../config/Connection';
import { CONFIG_ } from '../../config/Config';

var get = 'sent';
const PATH = process.env.REACT_APP_PATH_STORAGE;
export const __getDataGetfile = (params) => {
  return (dispatch) => {
    const { urlhash } = params;
    let config = CONFIG_({
      url: get + '/' + urlhash,
      method: 'GET',
      path: PATH,
    });
    dispatch({ type: 'LOADING_GETFILE_DOWNLOAD' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_GETFILE_DOWNLOAD',
            message: 'Success get data',
            data: data,
            code: code,
          });
        }
        return dispatch({ type: 'ERROR_GETFILE_DOWNLOAD', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_GETFILE_DOWNLOAD',
          message: 'Network error..',
        });
      });
  };
};
