export const ENDPOINT =
  process.env.REACT_APP_MODE !== 'production'
    ? process.env.REACT_APP_ENDPOINT
    : process.env.REACT_APP_PRODUCTION;

// export const HEADER = {};
export const HEADER = { apikey: process.env.REACT_APP_API_KEY };

export const AUTH = {
  auth: {
    username: process.env.REACT_APP_USERNAME,
    password: process.env.REACT_APP_PASSWORD,
  },
};
// export const AUTH = {};

export const HEADERDOWNLOAD = {
  responseType: 'blob',
};
const live_ep = 1;
// IDEA: FALSE IS LOCALHOST, TRUE IS ENDPOINT
export const CONFIG_ = ({
  url = '',
  method = 'GET',
  data = {},
  port = '8080',
  live = true,
  headers = {},
  path = '',
}) => {
  if (live_ep) {
    return {
      url: '/api/' + path + url,
      data: data,
      method: method,
      headers: {
        // ...HEADER,
        // ...headers,
      },
      // ...AUTH,
    };
  }
};

export const CONFIG_DOWNLOAD = ({
  url = '',
  method = 'GET',
  auth = {},
  contentType = 'application/json',
}) => {
  return {
    url: url,
    method: method,
    ...HEADERDOWNLOAD,
    headers: {
      'Content-Type': contentType,
      ...HEADER,
    },
    auth: {
      ...auth,
    },
  };
};
