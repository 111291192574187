import { CONNECTION } from '../../config/Connection';
import { CONFIG_, CONFIG_DOWNLOAD } from '../../config/Config';
import fileDownload from 'js-file-download';
import { __openModal } from '../modal';
import { getFilename } from '../../config/Helper';
var get = '';
var post = '';
var isLive = false;
export const __getFileWithoutPassword = (data) => {
  return (dispatch) => {
    const { file_url, content_type, is_secured, username, password, file_name } = data;
    const filename = getFilename(file_url);
    const file = file_name === '' ? filename : file_name;
    let config = CONFIG_DOWNLOAD({
      url: file_url,
      method: 'GET',
      contentType: content_type,
    });

    if (is_secured) {
      config = CONFIG_DOWNLOAD({
        url: file_url,
        method: 'GET',
        contentType: content_type,
        auth: {
          username: username,
          password: password,
        },
      });
    }
    // console.log(config);
    dispatch({ type: 'LOADING_DOWNLOAD' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code !== 500) {
          fileDownload(response, file);
          dispatch(__openModal({ modal: 'FORM_DOWNLOAD', open: false }));
          return dispatch({
            type: 'SUCCESS_DOWNLOAD',
            message: 'Success post data',
            code: 200,
          });
        }
        return dispatch({
          type: 'SUCCESS_DOWNLOAD',
          message: 'File download error, Please check your username and password',
          code: code,
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_DOWNLOAD', message: 'Network error..' });
      });
  };
};

export const _downloadFileWithPassword = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: 'POST' });
    dispatch({ type: 'LOADING_DOWNLOAD' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 201) {
          return dispatch({
            type: 'SUCCESS_DOWNLOAD',
            message: 'Success post data',
          });
        }
        return dispatch({ type: 'ERROR_DOWNLOAD', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_DOWNLOAD', message: 'Network error..' });
      });
  };
};
